import { getUnixTime } from "date-fns";
import { JWTModel } from "../auth/jwtModel";
import jwt_decode from "jwt-decode";

export function getLocalStorageKey(key: string) {
    const saved = localStorage.getItem(key);
    return JSON.parse(saved!);
}

export function saveTokenFromInput(token: string) {
    localStorage.setItem("user-token", JSON.stringify(token));
    window.history.pushState({}, document.title, window.location.pathname);
    return token;
}

export function deleteJwtToken() {
    const jwtToken = getLocalStorageKey('user-token');
    if (!!jwtToken) {
        localStorage.removeItem('user-token');
    }
}


export function getUserFromCookieUserToken(): JWTModel | null | undefined {
    const cookie = getLocalStorageKey('user-token');
    
    if (!!cookie) {
        const decoded: JWTModel = jwt_decode(cookie);
     //   verifyToken( decoded)
        
        const result = getUnixTime(new Date())
        if (decoded.exp) {
            if (Number(decoded.exp) < Number(result)) {
                deleteJwtToken();
                return null;
            } else {    
                return decoded;
            }
        } 
    }
}
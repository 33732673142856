import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ordiniSearchRequestDto } from "../interface/ordiniSearchRequestDto";
import axios from "axios";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";

interface ordiniSlice {
    ordiniSearchResult: any | null,
    ordiniSearchStatus: string,
    ordiniUpdateStatoResult: any | null
    ordiniUpdateStatoStatus: string,
    ordiniDettaglioResult: any | null,
    ordiniDettaglioStatus: string
}

const initialState:ordiniSlice = {
    ordiniSearchResult: null,
    ordiniSearchStatus: '',
    ordiniUpdateStatoResult: null,
    ordiniUpdateStatoStatus: '',
    ordiniDettaglioResult: null,
    ordiniDettaglioStatus: ''

}

export const searchOrdini = createAsyncThunk(
    'ordini/search',
    async (args: ordiniSearchRequestDto) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/ordine/search?pageNum=${args.pageNum - 1}&stato=${args.stato}&dataCreazioneInizio=${args.dataCreazioneInizio}&dataCreazioneFine=${args.dataCreazioneFine}`)

        return response.data
    }
)

export const dettaglioOrdini = createAsyncThunk(
    'ordini/dettaglio',
    async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/ordine/findById/${id}`)

        return response.data
    }
)

export const updateStato = createAsyncThunk(
    'ordini/updateStato',
    async (args:any) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/ordine/updateStato/${args.id}/${args.stato}` )

        return response.data
    }
)

export const ordiniSlice = createSlice({
    name: 'ordini',
    initialState,
    reducers:{

    },

    extraReducers: (builder) => {
        
        //search
        builder.addCase(searchOrdini.pending, (state: ordiniSlice) => {
            state.ordiniSearchStatus = STATUS_PENDING;
        });
        builder.addCase(searchOrdini.fulfilled, (state: ordiniSlice, action: any) => {
            state.ordiniSearchStatus = STATUS_FULLFILLED;
            state.ordiniSearchResult = action.payload;
        });
        builder.addCase(searchOrdini.rejected, (state: ordiniSlice) => {
            state.ordiniSearchStatus = STATUS_REJECTED;
        });

        //updateStato
        builder.addCase(updateStato.pending, (state:ordiniSlice) => {
            state.ordiniUpdateStatoStatus = STATUS_PENDING
        })
        builder.addCase(updateStato.fulfilled, (state:ordiniSlice, action:any) => {
            state.ordiniUpdateStatoStatus = STATUS_FULLFILLED;
            state.ordiniUpdateStatoResult = action.payload;
        })
        builder.addCase(updateStato.rejected, (state:ordiniSlice, action:any) => {
            state.ordiniUpdateStatoStatus = STATUS_REJECTED;
        })

        //dettaglio
        builder.addCase(dettaglioOrdini.pending, (state:ordiniSlice) => {
            state.ordiniDettaglioStatus = STATUS_PENDING
        })
        builder.addCase(dettaglioOrdini.fulfilled, (state:ordiniSlice, action:any) => {
            state.ordiniDettaglioStatus = STATUS_FULLFILLED;
            state.ordiniDettaglioResult = action.payload;
        })
        builder.addCase(dettaglioOrdini.rejected, (state:ordiniSlice, action:any) => {
            state.ordiniDettaglioStatus = STATUS_REJECTED;
        })
    }

})

export default ordiniSlice.reducer
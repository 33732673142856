import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import loaderReducer from "./loaderSlice";
import ordiniReducer from "./ordiniSlice";
import catalogoReducer from "./catalogoSlice";
import utenteReducer from "./utenteSlice";
import prodottoReducer from "./prodottoSlice";
import resoReducer from "./resoSlice";

export const store = configureStore({
    reducer:{
        authentication: authReducer,
        loader: loaderReducer,
        ordini: ordiniReducer,
        catalogo: catalogoReducer,
        utente: utenteReducer,
        prodotto: prodottoReducer,
        reso: resoReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        // AxiosHeaders not serializeable
        serializableCheck: false
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
import { Button, Image, Nav, Navbar } from "react-bootstrap";
import LogoImg from "../assets/image/logo-forno-amato.png";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faUserPlus, faBox, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket, faRetweet } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router";
import { HREF_ACCETTA_UTENTI, HREF_CATALOGHI, HREF_ORDINI, HREF_PRODOTTI, HREF_RESI } from "../constants/constants";


function Sidebar() {
    const [show, setShow] = useState(false);
    const onCollapse = () => setShow(!show);
    const showClass = show ? "show" : "";
    const location = useLocation();
    const { pathname } = location;


    const navigate = useNavigate();

    const goTo = (href: string) => {
        navigate(href);
    }

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5">
                    <Image src={LogoImg} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image src={LogoImg} className="card-img-top rounded-circle border-white" />
                                </div>
                               
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">
                            {show == false && <Nav.Item >
                                <Nav.Link>
                                    <span>
                                        <Image src={LogoImg} width={200} height={150} className="sidebar-icon svg-icon" />
                                    </span>
                                </Nav.Link>
                            </Nav.Item>}

                            <Nav.Item className={HREF_ORDINI === pathname ? "active" : ""} onClick={() => { goTo(HREF_ORDINI) }}  >
                                <Nav.Link >
                                    <span>
                                        <span className="sidebar-icon"><FontAwesomeIcon icon={faChartPie} /> </span>
                                        <span className="sidebar-text">Ordini</span>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className={HREF_RESI === pathname ? "active" : ""} onClick={() => { goTo(HREF_RESI) }}  >
                                <Nav.Link >
                                    <span>
                                        <span className="sidebar-icon"><FontAwesomeIcon icon={faRetweet} /> </span>
                                        <span className="sidebar-text">Resi</span>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className={HREF_ACCETTA_UTENTI === pathname ? "active" : ""} onClick={() => { goTo(HREF_ACCETTA_UTENTI) }} >
                                <Nav.Link>
                                    <span>
                                        <span className="sidebar-icon"><FontAwesomeIcon icon={faUserPlus} /> </span>
                                        <span className="sidebar-text">Accetta utenti</span>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className={HREF_CATALOGHI === pathname ? "active" : ""} onClick={() => { goTo(HREF_CATALOGHI) }}>
                                <Nav.Link>
                                    <span>
                                        <span className="sidebar-icon"><FontAwesomeIcon icon={faBook} /> </span>
                                        <span className="sidebar-text">Cataloghi</span>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item className={HREF_PRODOTTI === pathname ? "active" : ""} onClick={() => { goTo(HREF_PRODOTTI) }}>
                                <Nav.Link>
                                    <span>
                                        <span className="sidebar-icon"><FontAwesomeIcon icon={faBox} /> </span>
                                        <span className="sidebar-text">Prodotti</span>
                                    </span>
                                </Nav.Link>
                            </Nav.Item>




                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    )
}

export default Sidebar;
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import BgImage from "../../assets/illustration/signin.svg";
import { HREF_LOGIN } from "../../constants/constants";
import { z } from "zod";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { register, resetRegisterStatus } from "../../store/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { STATUS_FULLFILLED } from "../../store/store-constants";

export interface registerInterface {
  firstname: string,
  lastname: string,
  email: string,
  password: string
}

const schema = z.object({
  firstname: z.string({ required_error: 'Campo obbligatorio' }),
  lastname: z.string({ required_error: 'Campo obbligatorio' }),
  email: z.string({ required_error: 'Campo obbligatorio' }).email({message: 'Email non corretta'}),
  password: z.string({ required_error: 'Campo obbligatorio' })
})

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: ''
}


const Register = () => {

  const {registerStatus, registerResult} = useAppSelector((store) => store.authentication)

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: registerInterface) => {
    dispatch(register(values))
  }

  useEffect(() => {
    if(registerStatus == STATUS_FULLFILLED){
      navigate(HREF_LOGIN)
      dispatch(resetRegisterStatus())
    }
   console.log('register status::::>',registerStatus)
  }, [registerStatus])
  

  const getFieldError = (
    form: FormikProps<registerInterface>,
    fieldName: keyof registerInterface
  ): string => {
    if (form.touched[fieldName]) {
      return form.errors[fieldName] || "";
    } else {
      return "";
    }
  };


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Link to={HREF_LOGIN} className="text-gray-700">
              &lt; Ritorna al login
            </Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Crea un account</h3>
                </div>
                <form className="mt-4">
                  <div className="form-group mb-4">
                    <label htmlFor="firstname">Nome <span className="text-danger">*</span></label>
                    <input 
                      type="text" 
                      className={`form-control ${getFieldError(form, "firstname") ? 'is-invalid' : ''}`}
                      id="firstname" 
                      placeholder="Nome" 
                      name="firstname"
                      value={form.values.firstname}
                      onChange={form.handleChange}
                      autoFocus 
                    />
                    {getFieldError(form, "firstname") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "firstname")}
                        </div>
                      )}
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="lastname">Cognome <span className="text-danger">*</span></label>
                    <input 
                      type="text" 
                      className={`form-control ${getFieldError(form, "lastname") ? 'is-invalid' : ''}`}
                      id="lastname" 
                      name="lastname"
                      value={form.values.lastname}
                      onChange={form.handleChange}
                      placeholder="Cognome"
                    />
                    {getFieldError(form, "lastname") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "lastname")}
                        </div>
                      )}
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="email">Email <span className="text-danger">*</span></label>
                    <input 
                      type="email" 
                      className={`form-control ${getFieldError(form, "email") ? 'is-invalid' : ''}`}
                      id="email" 
                      name="email"
                      value={form.values.email}
                      onChange={form.handleChange}
                      placeholder="example@company.com"
                    />
                     {getFieldError(form, "email") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "email")}
                        </div>
                      )}
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="password">Password <span className="text-danger">*</span></label>
                    <input 
                      type="password" 
                      className={`form-control ${getFieldError(form, "password") ? 'is-invalid' : ''}`}
                      id="password" 
                      name="password"
                      value={form.values.password}
                      onChange={form.handleChange}
                      placeholder="Password"
                    />
                     {getFieldError(form, "password") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "password")}
                        </div>
                      )}
                  </div>
                  <button type="button" className="btn btn-primary w-100 mt-3" onClick={() => {form.handleSubmit()}}>Registrati</button>
                </form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Hai già un account?
                    <Link to={HREF_LOGIN} className="fw-bold"> Login </Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Register;

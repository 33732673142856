export const HREF_LOGIN = '/login';
export const HREF_ACCETTA_UTENTI = '/accetta-utente';
export const HREF_REGISTRATI = '/registrati';
export const HREF_RECUPERA_PASSWORD = '/forgot_password';

export const HREF_CATALOGHI = '/catalogo';
export const HREF_NUOVO_CATALOGO = '/nuovo-catalogo';
export const HREF_DETTAGLIO_CATALOGO = '/dettaglio-catalogo';
export const HREF_MODIFICA_CATALOGO = '/modifica-catalogo';

export const HREF_PRODOTTI = '/prodotto';
export const HREF_NUOVO_PRODOTTO = '/nuovo-prodotto';
export const HREF_DETTAGLIO_PRODOTTO = '/dettaglio-prodotto';
export const HREF_MODIFICA_PRODOTTO = '/modifica-prodotto';

export const HREF_ORDINI = '/ordine';
export const HREF_DETTAGLIO_ORDINE = '/dettaglio-ordine';

export const HREF_PROFILO_UTENTE = '/profilo-utente';

export const HREF_RESI = '/reso';
export const HREF_DETTAGLIO_RESO = '/dettaglio-reso';
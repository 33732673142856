import React, { useEffect } from "react";
import PageHeader from "../../components/page-header";
import { HREF_CATALOGHI } from "../../constants/constants";
import { Card } from "react-bootstrap";
import { z } from "zod";
import { CatalogoInsertRequestDto } from "../../interface/catalogoInsertRequestDto";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useNavigate } from "react-router";
import { insertCatalogo } from "../../store/catalogoSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { STATUS_FULLFILLED, STATUS_REJECTED } from "../../store/store-constants";
import { showNotifica } from "../../store/loaderSlice";
import { NOTIFICA_STATO } from "../../interface/notifica";

const schema = z.object({
    nome: z.string({ required_error: 'Campo obbligatorio' }),
    descrizione: z.string().optional()
})

const initialValues: CatalogoInsertRequestDto = {
    nome: '',
    descrizione: ''
}

function NuovoCatalogo() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { catalogoInsertStatus } = useAppSelector((state) => state.catalogo);

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: CatalogoInsertRequestDto) => {
        console.log(values);
        dispatch(insertCatalogo(values))
    }

    const getFieldError = (
        form: FormikProps<CatalogoInsertRequestDto>,
        fieldName: keyof CatalogoInsertRequestDto
    ): string => {
        if (form.touched[fieldName]) {
            return form.errors[fieldName] || "";
        } else {
            return "";
        }
    };

    const goToBack = () =>{
        navigate(HREF_CATALOGHI)
    }

    useEffect(()=>{
        if(catalogoInsertStatus == STATUS_FULLFILLED){
            form.resetForm()
        }
    }, [catalogoInsertStatus])

    return (
        <>
            <PageHeader title="Nuovo Catalogo" urlBack={HREF_CATALOGHI} showIcon={true} />
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="nome">Nome <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "nome") ? 'is-invalid' : ''}`}
                                    id="nome"
                                    name="nome"
                                    value={form.values.nome}
                                    onChange={form.handleChange}
                                    placeholder="Inserire il nome" />
                                {getFieldError(form, "nome") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "nome")}
                                    </div>
                                )}
                            </div>
                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="descrizione">Descrizione</label>
                                <textarea

                                    className="form-control"
                                    id="descrizione"
                                    name="descrizione"
                                    value={form.values.descrizione}
                                    onChange={form.handleChange}
                                    placeholder="Inserire la Decrizione" />
                            </div>
                        </div>
                   
                    <div className="d-flex justify-content-end mb-4 mt-3">
                        <button className="btn btn-outline-primary mt-2 me-2" type="button" id="button-cln" onClick={() => {goToBack()}}>
                            Annulla
                        </button>
                        <button className="btn btn-primary mt-2" onClick={() => form.handleSubmit()} type="button" id="button-addon2">
                            Salva
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default NuovoCatalogo;
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { faEdit, faEllipsisH, faEye, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { Alert, Button, ButtonGroup, Card, Dropdown, Modal, Nav, Pagination, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { z } from "zod";
import { ProdottoSearchRequestDto } from "../../interface/prodottoSearchRequestDto";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { deleteProdotto, searchProdotti } from "../../store/prodottoSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import TruncateText from "../../components/truncateText";
import PaginationComponent from "../../components/Pagination/Pagination";
import { HREF_DETTAGLIO_PRODOTTO, HREF_MODIFICA_PRODOTTO, HREF_NUOVO_PRODOTTO } from "../../constants/constants";
import { getAllCatalogo } from "../../store/catalogoSlice";

const schema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  idCatalogo: z.string().optional(),
})

const initialValues: ProdottoSearchRequestDto = {
  description: '',
  idCatalogo: '',
  name: '',
  pageNum: 1
}

function Prodotto() {

  const dispatch = useAppDispatch();
  const { prodottoSearchResult, prodottoSearchStatus } = useAppSelector((state) => state.prodotto);
  const [currentPage, setCurrentPage] = useState(1);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [prodottoToDelete, setProdottoToDelete] = useState<any>(null);
  const { catalogoGetAllResult } = useAppSelector((state) => state.catalogo);

  const [prodotti, setProdotti] = useState<any>({
    data: [],
    totalElements: 0,
    pageNum: 1,
    pageSize: 10,
  })

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })

  const handleSubmit = (values: ProdottoSearchRequestDto) => {
    dispatch(searchProdotti(values));
  }

  useEffect(() => {
    dispatch(searchProdotti(initialValues))
    dispatch(getAllCatalogo())
  }, [])

  useEffect(() => {
    setProdotti(prodottoSearchResult)
  }, [prodottoSearchResult])

  const reset = () => {
    form.resetForm();
    setCurrentPage(1)
    form.handleSubmit();
  }

  const avviaRicerca = () => {
    form.setFieldValue('pageNum', 1);
    setCurrentPage(1);
    form.handleSubmit()
  }

  const handlePageChange = (pageNum: number) => {
    form.setFieldValue('pageNum', pageNum)
    setCurrentPage(pageNum)
    form.handleSubmit()
  };

  let hasResult =
    prodottoSearchResult && prodottoSearchResult.totalElements
      ? prodottoSearchResult.totalElements > 0
      : false;

  const navigate = useNavigate();

  const tastoAggiungiOrdine = {
    buttonProperties: { variant: 'outline-primary' },
    title: 'Nuovi prodotti',
    buttonColor: 'primary',
    action: () => navigate(HREF_NUOVO_PRODOTTO),
    showButtonIcon: true,
    icon: faPlus,
    iconColor: 'primary',
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setProdottoToDelete(null);
  };

  const modalDelete = (prodotto: any) => {
    setProdottoToDelete(prodotto)
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (prodottoToDelete) {
      await dispatch(deleteProdotto(prodottoToDelete.id));
      setShowDeleteModal(false);
      setProdottoToDelete(null);
      dispatch(searchProdotti(form.values))
    }
  }

  return (
    <>
      <PageHeader title="Prodotti" buttons={[tastoAggiungiOrdine]} />

      <div className="mt-4">
        <div className="row mb-4">
          <div className="col-md-4">
            <label htmlFor="name">Nome prodotto</label>
            <input
              type="text"
              id="name"
              placeholder="Inserisci il nome"
              className="form-control"
              name="name"
              value={form.values.name}
              onChange={form.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="description">Descrizione</label>
            <input
              type="text"
              id="description"
              placeholder="Inserisci la description"
              className="form-control"
              name="description"
              value={form.values.description}
              onChange={form.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="idCatalogo">Catalogo</label>
            <select
              className={`form-control`}
              id="idCatalogo"
              name="idCatalogo"
              value={form.values.idCatalogo}
              onChange={form.handleChange}
            >
              <option value="">Seleziona</option>

              {catalogoGetAllResult != null && catalogoGetAllResult?.data.map((value: any) => (
                <option key={value.id} value={value.id}>{value.nome}</option>
              ))}

            </select>

          </div>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <button
            className="btn btn-outline-primary mt-2 me-2"
            type="button"
            id="button-cln"
            onClick={() => { reset() }}
          >
            Reset
          </button>
          <button className="btn btn-primary mt-2" type="button" id="button-addon2" onClick={() => avviaRicerca()}>
            <FontAwesomeIcon icon={faSearch} className="me-1" />
            Cerca
          </button>
        </div>
      </div>
      {prodotti != null && prodottoSearchStatus === STATUS_FULLFILLED && prodotti.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
      {prodottoSearchStatus === STATUS_FULLFILLED && hasResult && (
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">Codice</th>
                  <th className="border-bottom">Nome</th>
                  <th className="border-bottom">Descrizione</th>
                  <th className="border-bottom">Catalogo</th>
                  <th className="border-bottom">tipo</th>
                  <th className="border-bottom"></th>
                </tr>
              </thead>
              <tbody>
                {prodotti != null && prodotti.data.map((t: any, i: any) => (
                  <tr key={t.id}>
                    <td>{t.codice}</td>
                    <td>
                      <span className="fw-normal">{t.name}</span>
                    </td>
                    <td>
                      <span className="fw-normal">{<TruncateText text={t.description} maxLength={50} idTooltip={`${i}`} />}{ }</span>
                    </td>
                    <td>
                      <span className="fw-normal">{t.nomeCatalogo}</span>
                    </td>
                    <td>
                      <span className="fw-normal">{t.tipo}</span>
                    </td>
                    <td>
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                          <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => navigate(`${HREF_DETTAGLIO_PRODOTTO}/${t.id}`)}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Dettaglio
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate(`${HREF_MODIFICA_PRODOTTO}/${t.id}`)}>
                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Modifica
                          </Dropdown.Item>
                          <Dropdown.Item className="text-danger" onClick={() => { modalDelete(t) }}>
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Elimina
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              {prodotti != null ? (<PaginationComponent
                totalCount={prodotti.totalElements}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={prodotti.pageSize}
                onPageChange={handlePageChange}
                className="justify-content-center"
              />) : <></>}
            </Card.Footer>
          </Card.Body>
        </Card>
      )}

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma Eliminazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p>Sei sicuro di voler eliminare questo prodotto?</p>
          <p>Questa azione non può essere annullata.</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Annulla
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Prodotto;
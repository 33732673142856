import axios, { AxiosResponse } from "axios";
import React from "react";
import { getLocalStorageKey } from "../utility/cookie";
import { HREF_LOGIN } from "../constants/constants";
import { hideLoader, showLoader, showNotifica } from "../store/loaderSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { NOTIFICA_STATO } from "../interface/notifica";

const jwtInterceptor = (dispatch: Dispatch) => {
    let method: string = '';
    let url: string = '';
    let body: any = null;




    axios.interceptors.request.use(request => {
        console.log('REQUEST >>> ', request);

        dispatch(showLoader());
        const isApiUrl = request.url?.startsWith(process.env.REACT_APP_PUBLIC_URL!);
        if ("true" == 'true') {
            const jwtToken = getLocalStorageKey("user-token")
            if (isApiUrl && (!!jwtToken)) {
                request.headers.Authorization = `Bearer ${jwtToken}`
                request.withCredentials = true
            }
        }
        method = request.method || '';
        url = request.url || '';
        body = request.data;

        return request
    });

    axios.interceptors.response.use(
        (response: AxiosResponse<any, any>) => {
            dispatch(hideLoader());
            console.log('RESPONSE >>> ', response);

            if (method !== 'get') {
                dispatch(showNotifica({
                    titolo: 'OPERAZIONE COMPLETATA',
                    stato: response.status,
                    messaggio: 'Operazione richiesta completata con successo!',
                    tipo: NOTIFICA_STATO.success
                }))
                return response;
            }


            return response; // Restituisci il response per soddisfare il tipo previsto
        },
        (error) => {
            dispatch(hideLoader());
            console.error('RESPONSE ERROR >>> ', error);
            if (error.response) {
                console.log('Response status:', error.response.status);
                switch (true) {
                    case (error.response.status === 400):
                        dispatch(showNotifica({
                            titolo: 'DATI NON CORRETTI',
                            stato: error.response.status,
                            messaggio: error.response.data.parameters != null ? error.response.data.parameters[0].value : 'Si è verificato un errore durante la richiesta.',
                            tipo: NOTIFICA_STATO.error
                        }))
                        break;
                    case (error.response.status === 401):
                        window.location.href = HREF_LOGIN;
                        break;
                    case (error.response.status === 403):
                        dispatch(showNotifica({
                            titolo: 'OPERAZIONE NON CONSENTITA',
                            stato: error.response.status,
                            messaggio: error.response.data instanceof ArrayBuffer ? 'ACCESSO NEGATO' : error.response.data.parameters[0].value || ' - - ',
                            tipo: NOTIFICA_STATO.error
                        }))
                        break;
                    default:
                        console.error(error);
                        dispatch(showNotifica({
                            titolo: 'ERRORE GENERICO',
                            stato: error.response.status,
                            messaggio: error.response.data.parameters != null ? error.response.data.parameters[0].value : 'Si è verificato un errore durante la richiesta.',
                            tipo: NOTIFICA_STATO.error
                        }))
                        break;
                }
                return error;
            }
            else {
                console.error('No response received:', error);
            }
            return Promise.reject(error);
        }
    );


}
export default jwtInterceptor;
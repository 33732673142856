import React from "react";
import Navbars from "./navbar";
import Sidebar from "./sidebar";
import { Outlet } from "react-router";


function Layout() {

    return (
        <>
            <Sidebar />
            <main className="content">
                <Navbars />
                <Outlet />
            </main>
        </>
    )
}

export default Layout;
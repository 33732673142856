import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export interface TruncateProps {
    maxLength?: number;
    text?: string;
    idTooltip?: string;
}

const TruncateText = (props: TruncateProps) => {

    // Funzione per troncare il testo
    const truncate = (str: string) => {
        if (str) {
            if (props.maxLength) {
                return str.length > props.maxLength ? str.substring(0, props.maxLength) + "..." : str;
            } else {
                return str.length > 30 ? str.substring(0, 30) + "..." : str;
            }
        }
        return '';
    }

    // Tooltip che verrà visualizzato al passaggio del mouse
    const renderTooltip = (props: any) => (
        <Tooltip id={`tooltip_${props.idTooltip}`} {...props}>
            {props.text}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="top"
            overlay={renderTooltip({ text: props.text, idTooltip: props.idTooltip })}
        >
            <span>{truncate(props.text || '')}</span>
        </OverlayTrigger>
    );
}

export default TruncateText;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import { HREF_LOGIN } from "../../constants/constants";
import { z } from "zod";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { forgotPasswordEmail, verifyOtp, resetPassword } from "../../store/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { STATUS_FULLFILLED, STATUS_REJECTED } from "../../store/store-constants";
import { Bounce, toast } from "react-toastify";

interface EmailForgotPasswordDto {
    email: string,
}

interface OtpDto {
    email: string,
    otp: number | undefined,
}

interface ResetPasswordDto {
    email: string,
    password: string,
    confirmPassword: string
}

const schemaEmail = z.object({
    email: z.string({ required_error: 'Campo obbligatorio' }).email({ message: 'Email non valida' })
});

const schemaOtp = z.object({
    otp: z.number({ required_error: 'Campo obbligatorio' }).max(999999, { message: 'Codice OTP deve essere di 6 cifre' })
});

const schemaResetPassword = z.object({
    password: z.string().min(6, { message: 'La nuova password deve avere almeno 6 caratteri' }),
    confirmPassword: z.string({ required_error: 'Campo obbligatorio' }).min(6, { message: 'La nuova password deve avere almeno 6 caratteri' }),
});

const ForgotPassword: React.FC = () => {


    const [step, setStep] = useState<1 | 2 | 3>(1);
    const [email, setEmail] = useState<string>('');
    const dispatch = useAppDispatch();
    const { forgotPasswordEmailResult, forgotPasswordEmailStatus, verifyOtpResult, verifyOtpStatus, resetPasswordResult, resetPasswordStatus } = useAppSelector((store) => store.authentication);
    const navigate = useNavigate()


    const formEmail = useFormik({
        initialValues: { email: '' },
        validationSchema: toFormikValidationSchema(schemaEmail),
        onSubmit: (values) => {
            handleSubmitEmail(values);
        },
    });

    const formOtp = useFormik({
        initialValues: { otp: undefined, email: '' },
        validationSchema: toFormikValidationSchema(schemaOtp),
        onSubmit: (values) => {
            handleSubmitOtp(values);
        },
    });

    const formResetPassword = useFormik({
        initialValues: { email: '', password: '', confirmPassword: '' },
        validationSchema: toFormikValidationSchema(schemaResetPassword),
        onSubmit: (values) => {
            handleSubmitResetPassword(values);
        },
    });

    const handleSubmitEmail = (values: EmailForgotPasswordDto) => {
        dispatch(forgotPasswordEmail(values));
        setEmail(values.email);
    };

    const handleSubmitOtp = (values: OtpDto) => {
        dispatch(verifyOtp(values));
    };

    const handleSubmitResetPassword = (values: ResetPasswordDto) => {
        dispatch(resetPassword(values));
    };

    useEffect(() => {
        if (forgotPasswordEmailStatus === STATUS_FULLFILLED) {
            setStep(2);
        } else if (forgotPasswordEmailStatus === STATUS_REJECTED) {
            toast.error('Errore...riprovare più tardi', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            });
        }
        if (verifyOtpStatus === STATUS_FULLFILLED) {
            setStep(3);
        }else if( verifyOtpStatus === STATUS_REJECTED){
            toast.error('Errore...riprovare più tardi', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            });
        }
        if (resetPasswordStatus === STATUS_FULLFILLED) {
            toast.success('Cambio password avventuo con successo', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            });
            navigate(HREF_LOGIN)
        }else if( resetPasswordStatus === STATUS_REJECTED){
            toast.error('Errore...riprovare più tardi', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            });
        }
    }, [forgotPasswordEmailStatus, verifyOtpStatus, resetPasswordStatus]);

    useEffect(() => {
        formOtp.setFieldValue('email', email)
        formResetPassword.setFieldValue('email', email)
    }, [email])

    return (
        <main>
            <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <p className="text-center">
                            <Link to={HREF_LOGIN} className="text-gray-700 text-decoration-none">
                                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Torna al login
                            </Link>
                        </p>
                        <div className="col-xs-12 d-flex align-items-center justify-content-center">
                            <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                {step === 1 && (
                                    <>
                                        <h3>Hai dimenticato la password?</h3>
                                        <p className="mb-4">Non preoccuparti! Basta digitare la tua email e ti invieremo un codice per reimpostare la tua password!</p>
                                        <form onSubmit={formEmail.handleSubmit}>
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label">Email</label>
                                                <div className="form-group">
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        className={`form-control ${formEmail.errors.email && formEmail.touched.email ? 'is-invalid' : ''}`}
                                                        name="email"
                                                        autoFocus
                                                        placeholder="john@company.com"
                                                        value={formEmail.values.email}
                                                        onChange={formEmail.handleChange}
                                                    />
                                                    {formEmail.errors.email && formEmail.touched.email && (
                                                        <div className="invalid-feedback">
                                                            {formEmail.errors.email}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary w-100">
                                                Recupera password
                                            </button>
                                        </form>
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <h3>Inserisci il codice OTP</h3>
                                        <p className="mb-4">Abbiamo inviato un codice al tuo indirizzo email. Inseriscilo qui sotto per continuare.</p>
                                        <form onSubmit={formOtp.handleSubmit}>
                                            <div className="mb-4">
                                                <label htmlFor="otp" className="form-label">Codice OTP</label>
                                                <div className="form-group">
                                                    <input
                                                        id="otp"
                                                        type="number"
                                                        className={`form-control ${formOtp.errors.otp && formOtp.touched.otp ? 'is-invalid' : ''}`}
                                                        name="otp"
                                                        placeholder="123456"
                                                        value={formOtp.values.otp}
                                                        onChange={formOtp.handleChange}
                                                    />
                                                    {formOtp.errors.otp && formOtp.touched.otp && (
                                                        <div className="invalid-feedback">
                                                            {formOtp.errors.otp}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary w-100">
                                                Verifica codice OTP
                                            </button>
                                        </form>
                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        <h3>Imposta una nuova password</h3>
                                        <p className="mb-4">Inserisci la tua nuova password qui sotto.</p>
                                        <form onSubmit={formResetPassword.handleSubmit}>
                                            <div className="mb-4">
                                                <label htmlFor="newPassword" className="form-label">Nuova Password</label>
                                                <div className="form-group">
                                                    <input
                                                        id="newPassword"
                                                        type="password"
                                                        className={`form-control ${formResetPassword.errors.password && formResetPassword.touched.password ? 'is-invalid' : ''}`}
                                                        name="password"
                                                        placeholder="Nuova password"
                                                        value={formResetPassword.values.password}
                                                        onChange={formResetPassword.handleChange}
                                                    />
                                                    {formResetPassword.errors.password && formResetPassword.touched.password && (
                                                        <div className="invalid-feedback">
                                                            {formResetPassword.errors.password}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="confirmPassword" className="form-label">Conferma Password</label>
                                                <div className="form-group">
                                                    <input
                                                        id="confirmPassword"
                                                        type="password"
                                                        className={`form-control ${formResetPassword.errors.confirmPassword && formResetPassword.touched.confirmPassword ? 'is-invalid' : ''}`}
                                                        name="confirmPassword"
                                                        placeholder="Nuova password"
                                                        value={formResetPassword.values.confirmPassword}
                                                        onChange={formResetPassword.handleChange}
                                                    />
                                                    {formResetPassword.errors.confirmPassword && formResetPassword.touched.confirmPassword && (
                                                        <div className="invalid-feedback">
                                                            {formResetPassword.errors.confirmPassword}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary w-100">
                                                Imposta nuova password
                                            </button>
                                        </form>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ForgotPassword;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { resoSearchRequestDto } from "../interface/resoSearchRequestDto"
import axios from "axios"
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants"

interface resoSlice {
    resoSearchResult: any | null,
    resoSearchStatus: string,
    resoUpdateStatoResult: any | null
    resoUpdateStatoStatus: string,
    resoDettaglioResult: any | null,
    resoDettaglioStatus: string
}

const initialState: resoSlice = {
    resoSearchResult: null,
    resoSearchStatus: '',
    resoUpdateStatoResult: null,
    resoUpdateStatoStatus: '',
    resoDettaglioResult: null,
    resoDettaglioStatus: ''
}

export const searchResi = createAsyncThunk(
    'reso/search',
    async (args:resoSearchRequestDto) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/reso/search?pageNum=${args.pageNum - 1}&stato=${args.stato}&dataResoInizio=${args.dataResoInizio}&dataResoFine=${args.dataResoFine}`)

        return response.data
    }
)

export const dettaglioReso = createAsyncThunk(
    'reso/dettaglio',
    async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/reso/findById/${id}`)

        return response.data
    }
)

export const updateStato = createAsyncThunk(
    'reso/updateStato',
    async (args:any) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/reso/updateStato/${args.id}/${args.stato}` )

        return response.data
    }
)

export const resoSlice = createSlice({
    name: 'reso',
    initialState,
    reducers: {

    },

    extraReducers:(builder) => {
        
        //search
        builder.addCase(searchResi.pending, (state: resoSlice) => {
            state.resoSearchStatus = STATUS_PENDING;
        });
        builder.addCase(searchResi.fulfilled, (state: resoSlice, action: any) => {
            state.resoSearchStatus = STATUS_FULLFILLED;
            state.resoSearchResult = action.payload;
        });
        builder.addCase(searchResi.rejected, (state: resoSlice) => {
            state.resoSearchStatus = STATUS_REJECTED;
        });

        //updateStato
        builder.addCase(updateStato.pending, (state:resoSlice) => {
            state.resoUpdateStatoStatus = STATUS_PENDING
        })
        builder.addCase(updateStato.fulfilled, (state:resoSlice, action:any) => {
            state.resoUpdateStatoStatus = STATUS_FULLFILLED;
            state.resoUpdateStatoResult = action.payload;
        })
        builder.addCase(updateStato.rejected, (state:resoSlice, action:any) => {
            state.resoUpdateStatoStatus = STATUS_REJECTED;
        })

        //dettaglio
        builder.addCase(dettaglioReso.pending, (state:resoSlice) => {
            state.resoDettaglioStatus = STATUS_PENDING
        })
        builder.addCase(dettaglioReso.fulfilled, (state:resoSlice, action:any) => {
            state.resoDettaglioStatus = STATUS_FULLFILLED;
            state.resoDettaglioResult = action.payload;
        })
        builder.addCase(dettaglioReso.rejected, (state:resoSlice, action:any) => {
            state.resoDettaglioStatus = STATUS_REJECTED;
        })
    },
})

export default resoSlice.reducer
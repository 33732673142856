import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { JWTModel } from "../auth/jwtModel";
import { getUserFromCookieUserToken } from "../utility/cookie";
import { z } from "zod";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useAppDispatch } from "../hooks";
import { modificaPassword } from "../store/utenteSlice";

interface updatePassword {
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
}

const schema = z.object({
    currentPassword: z.string({ required_error: 'Campo obbligatorio' }),
    newPassword: z.string({ required_error: 'Campo obbligatorio' }),
    confirmNewPassword: z.string({ required_error: 'Campo obbligatorio' }),
})

const initialValues: updatePassword = {
    currentPassword: '',
    confirmNewPassword: '',
    newPassword: '',
}

export const GeneralInfoForm = () => {

    const dispatch = useAppDispatch();

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values: updatePassword) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: updatePassword) => {
        dispatch(modificaPassword(values))
    }

    const getFieldError = (
        form: FormikProps<updatePassword>,
        fieldName: keyof updatePassword
    ): string => {
        if (form.touched[fieldName]) {
            return form.errors[fieldName] || "";
        } else {
            return "";
        }
    };

    const userResult: JWTModel | null | undefined = getUserFromCookieUserToken();

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">Informazioni generali</h5>
                <Form>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="nome">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control required type="text" readOnly value={userResult?.nome} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="cognome">
                                <Form.Label>Cognome</Form.Label>
                                <Form.Control required type="text" readOnly value={userResult?.cognome} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="emal">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" readOnly value={userResult?.email} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="stato">
                                <Form.Label>Stato</Form.Label>
                                <Form.Control required type="text" readOnly value={userResult?.attivo ? 'Attivo' : 'Disattivo'} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="role">
                                <Form.Label>Ruolo</Form.Label>
                                <Form.Control required type="text" readOnly value={userResult?.ruolo == 'ADMIN' ? 'Amministratore' : 'Utente'} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h5 className="my-4">Modifica password</h5>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="currentPassword">
                                <Form.Label>Password corrente <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    className={`form-control ${getFieldError(form, "currentPassword") ? 'is-invalid' : ''}`}
                                    name="currentPassword"
                                    placeholder="Password corrente"
                                    value={form.values.currentPassword}
                                    onChange={form.handleChange}
                                    type="password"
                                />
                                {getFieldError(form, "currentPassword") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "currentPassword")}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="newPasswird">
                                <Form.Label>Nuova password <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    className={`form-control ${getFieldError(form, "newPassword") ? 'is-invalid' : ''}`}
                                    name="newPassword"
                                    placeholder="Nuova password"
                                    value={form.values.newPassword}
                                    onChange={form.handleChange}
                                    type="password"
                                />
                                {getFieldError(form, "newPassword") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "newPassword")}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="confirmNewPasswird">
                                <Form.Label>Conferma nuova password <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    className={`form-control ${getFieldError(form, "confirmNewPassword") ? 'is-invalid' : ''}`}
                                    name="confirmNewPassword"
                                    placeholder="Conferma nuova password"
                                    value={form.values.confirmNewPassword}
                                    onChange={form.handleChange}
                                    type="password"
                                />
                                {getFieldError(form, "confirmNewPassword") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "confirmNewPassword")}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <Button variant="primary" type="button" onClick={() => { form.handleSubmit() }}>Modifica password</Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};

import React, { useEffect, useState } from "react"
import { HREF_CATALOGHI } from "../../constants/constants"
import PageHeader from "../../components/page-header"
import { Card } from "react-bootstrap"
import { z } from "zod"
import { CatalogoUpdateRequestDto } from "../../interface/catalogoUpdateRequestDto"
import { FormikProps, useFormik } from "formik"
import { useNavigate, useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { dettaglioCatalogo, updateCatalogo } from "../../store/catalogoSlice"
import { STATUS_FULLFILLED } from "../../store/store-constants"

const schema = z.object({
    nome: z.string({ required_error: 'Campo obbligatorio' }),
    descrizione: z.string().optional()
})

const initialValues: CatalogoUpdateRequestDto = {
    id: '',
    nome: '',
    descrizione: ''
}

function ModificaCatalogo() {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { catalogoDettaglioResult, catalogoDettaglioStatus } = useAppSelector((state) => state.catalogo);
    const [catalogo, setCatalogo] = useState<any>(null);
    const { id } = useParams()


    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: CatalogoUpdateRequestDto) => {
        console.log(values);
        dispatch(updateCatalogo(values));

    }

    const getFieldError = (
        form: FormikProps<CatalogoUpdateRequestDto>,
        fieldName: keyof CatalogoUpdateRequestDto
    ): string => {
        if (form.touched[fieldName]) {
            return form.errors[fieldName] || "";
        } else {
            return "";
        }
    };

    useEffect(() => {
        if (id != undefined) {
            dispatch(dettaglioCatalogo(id))
        }

    }, [id])

    useEffect(() => {
        if (catalogoDettaglioStatus === STATUS_FULLFILLED) {
            form.setFieldValue('id', id)
            form.setFieldValue('nome', catalogoDettaglioResult.nome);
            form.setFieldValue('descrizione', catalogoDettaglioResult.description)
        }
    }, [catalogoDettaglioStatus]);

    const goToBack = () => {
        navigate(HREF_CATALOGHI)
    }

    return (
        <>
            <PageHeader title="Modifica Catalogo" urlBack={HREF_CATALOGHI} showIcon={true} />
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="nome">Nome <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "nome") ? 'is-invalid' : ''}`}
                                    id="nome"
                                    name="nome"
                                    value={form.values.nome}
                                    onChange={form.handleChange}/>
                                {getFieldError(form, "nome") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "nome")}
                                    </div>
                                )}
                            </div>
                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="descrizione">Descrizione</label>
                                <textarea

                                    className="form-control"
                                    id="descrizione"
                                    name="descrizione"
                                    value={form.values.descrizione}
                                    onChange={form.handleChange} />
                            </div>
                        </div>
                    <div className="d-flex justify-content-end mb-4 mt-3">
                        <button className="btn btn-outline-primary mt-2 me-2" type="button" id="button-cln" onClick={() => { goToBack() }}>
                            Annulla
                        </button>
                        <button className="btn btn-primary mt-2" onClick={() => form.handleSubmit()} type="button" id="button-addon2">
                            Salva
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default ModificaCatalogo
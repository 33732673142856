import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import React, { useEffect, useState } from "react";
import { dettaglioReso } from "../../store/resoSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import PageHeader from "../../components/page-header";
import { HREF_RESI } from "../../constants/constants";
import { Card, ListGroup } from "react-bootstrap";
import { format } from "date-fns";

function DettaglioResi(){

    const {id} = useParams();
    const dispatch = useAppDispatch();
    const { resoDettaglioResult, resoDettaglioStatus} = useAppSelector((state)=> state.reso);
    const [reso, setReso] = useState<any>(null);

    useEffect(() => {
        if (id != undefined) {
            dispatch(dettaglioReso(id));
        }
    }, [id])

    useEffect(() => {
        if (resoDettaglioStatus === STATUS_FULLFILLED) {
            setReso(resoDettaglioResult);
        }
    }, [resoDettaglioStatus])

    return(
        <>
          <PageHeader title="Dettaglio Reso" urlBack={HREF_RESI} showIcon={true} />
          <div className="container mt-4">
                <Card border="light" className="shadow-lg rounded">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Id: </span>
                                    <span className="text-muted"> {reso?.id}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Effettuato da : </span>
                                    <span className="text-muted">{reso?.nomeUtente} {reso?.cognomeUtente}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Id Ordine: </span>
                                    <span className="text-muted">{reso?.ordineId} </span>
                                </Card.Text>

                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Data reso: </span>
                                    <span className="text-muted">{reso?.dataReso!+ null ? format(new Date(reso?.dataReso), 'dd/MM/yyyy') : ''}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Stato: </span>
                                    <span className="text-muted">{reso?.stato}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Reso totale: </span>
                                    <span className="text-muted">{reso?.total == true ? 'SI': 'NO'}</span>
                                </Card.Text>

                            </div>

                        </div>
                        <Card.Header className="fs-4 fw-bold mb-3">Prodotti</Card.Header>
                        <ListGroup variant="flush">
                            {reso?.dettagliReso.length ? (
                                reso.dettagliReso.map((details: any) => (
                                    <ListGroup.Item key={details.id} className="d-grid gap-2">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Nome:</strong> <br />
                                                <span className="text-muted">{details.nomeProdotto}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Quantità:</strong> <br />
                                                <span className="text-muted">{details.quantitaReso}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Formato:</strong> <br />
                                                <span className="text-muted">{details.unitaDiMisura}</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item className="text-center">Nessun prodotto disponibile</ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default DettaglioResi;
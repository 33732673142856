import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { attivaUtente, utentiDaAttivare } from "../../store/utenteSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Alert, Button, ButtonGroup, Card, Dropdown, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH, faEye } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../components/Pagination/Pagination";



function AcceptUser() {

    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const { utentiDaAttivareResult, utentiDaAttivareStatus } = useAppSelector((state) => state.utente)
    const [utenti, setUtenti] = useState<any>({
        data: [],
        totalElements: 0,
        pageNum: 1,
        pageSize: 10,
    })
    useEffect(() => {
        dispatch(utentiDaAttivare(currentPage))
    }, [])

    useEffect(() => {
        setUtenti(utentiDaAttivareResult)
    }, [utentiDaAttivareStatus])

    let hasResult =
        utentiDaAttivareResult && utentiDaAttivareResult.totalElements
            ? utentiDaAttivareResult.totalElements > 0
            : false;

    const handlePageChange = (pageNum: number) => {
        dispatch(utentiDaAttivare(pageNum))
        setCurrentPage(pageNum)
    };

    const handleAttivaUtente = async (id: string) => {
        await dispatch(attivaUtente(id));
        dispatch(utentiDaAttivare(currentPage))
    }

    return (
        <>
            <PageHeader title="Accetta utenti" />
            {utenti != null && utentiDaAttivareStatus === STATUS_FULLFILLED && utenti.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
            {utentiDaAttivareStatus === STATUS_FULLFILLED && hasResult && (
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="pt-0">
                        <Table hover className="user-table align-items-center">
                            <thead>
                                <tr>
                                    <th className="border-bottom">#</th>
                                    <th className="border-bottom">Nome</th>
                                    <th className="border-bottom">Cognome</th>
                                    <th className="border-bottom">Email</th>
                                    <th className="border-bottom">Catalogo</th>
                                    <th className="border-bottom"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {utenti != null && utenti.data.map((t: any, i: any) => (
                                    <tr key={t.id}>
                                        <td>{t.id}</td>
                                        <td>
                                            <span className="fw-normal">{t.firstname}</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">{t.lastname}</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">{t.email}</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">{t.catalogo.nome}</span>
                                        </td>
                                        <td>
                                            <Dropdown as={ButtonGroup}>
                                                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                    <span className="icon icon-sm">
                                                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => { handleAttivaUtente(t.id) }}>
                                                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Attiva
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                            {utenti != null ? (<PaginationComponent
                                totalCount={utenti.totalElements}
                                siblingCount={1}
                                currentPage={currentPage}
                                pageSize={utenti.pageSize}
                                onPageChange={handlePageChange}
                                className="justify-content-center"
                            />) : <></>}
                        </Card.Footer>
                    </Card.Body>
                </Card>
            )}
        </>
    )
}

export default AcceptUser;
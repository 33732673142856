import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { JWTModel } from "../auth/jwtModel";
import { getUserFromCookieUserToken } from "../utility/cookie";

function ProfileCardWidget() {

    const userResult:JWTModel | null | undefined = getUserFromCookieUserToken();

    return (
        <Card border="light" className="text-center p-0 mb-4">
            <div style={{ backgroundImage: `url()` }} className="profile-cover rounded-top" />
            <Card.Body className="pb-5">
                <Card.Img src={`https://ui-avatars.com/api/?name=${userResult?.nome.charAt(0)}+${userResult?.cognome.charAt(0)}`} alt="Neil Portrait" className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
                <Card.Title>{userResult?.nome} {userResult?.cognome}</Card.Title>
                <Card.Subtitle className="fw-normal">{userResult?.email}</Card.Subtitle>
            </Card.Body>
        </Card>
    );


}

export default ProfileCardWidget;
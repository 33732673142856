import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import BgImage from '../../assets/illustration/signin.svg';
import { HREF_ORDINI, HREF_RECUPERA_PASSWORD, HREF_REGISTRATI } from "../../constants/constants";
import { z } from "zod";
import { FormikProps, useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { login } from "../../store/authSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { deleteJwtToken, saveTokenFromInput } from "../../utility/cookie";

export interface loginInterface {
  email: string,
  password: string,
}

const schema = z.object({
  email: z.string({required_error:'Campo obbligatorio'}).email('Email non valida'),
  password: z.string({required_error:'Campo obbligatorio'}),
})

const initialValues: loginInterface = {
  email: '',
  password: '',
}

function LoginForm() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {loginStatus, loginResult} = useAppSelector((store) => store.authentication)

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if(loginStatus == STATUS_FULLFILLED){
      if(loginResult != undefined){
        saveTokenFromInput(loginResult.token)
        navigate(HREF_ORDINI);
      }    
    } 
  }, [loginStatus])

  useEffect(()=>{
    deleteJwtToken()
  }, [])
  

  const handleSubmit = (values: loginInterface) => {
    console.log(values);

    dispatch(login(values))
  }

  const getFieldError = (
    form: FormikProps<loginInterface>,
    fieldName: keyof loginInterface
  ): string => {
    if (form.touched[fieldName]) {
      return form.errors[fieldName] || "";
    } else {
      return "";
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <div className="container">
          <div className="row justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Accedi</h3>
                </div>
                <form className="mt-4" onSubmit={form.handleSubmit}>
                  <div className="form-row mb-4">
                    <label htmlFor="email">Email</label>
                    <div className="col">
                      <input
                        autoFocus
                        type="email"
                        id="email"
                        className={`form-control ${getFieldError(form, "email") ? 'is-invalid' : ''}`}
                        placeholder="example@company.com"
                        name="email"
                        value={form.values.email}
                        onChange={form.handleChange}
                      />
                      {getFieldError(form, "email") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "email")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <label htmlFor="password">Password</label>
                    <div className="col">
                      <input
                        type="password"
                        id="password"
                        className={`form-control ${getFieldError(form, "password") ? 'is-invalid' : ''}`}
                        placeholder="Password"
                        name="password"
                        value={form.values.password}
                        onChange={form.handleChange}
                      />
                      {getFieldError(form, "password") && (
                        <div className="invalid-feedback">
                          {getFieldError(form, "password")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center mb-4">
                    <a onClick={ () => (navigate(HREF_RECUPERA_PASSWORD))} className="small text-end">Password dimenticata?</a>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Login
                  </button>
                </form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Non sei registrato?
                    <Link to={HREF_REGISTRATI} className="fw-bold">
                      {` Registrati`}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoginForm;

import React from 'react';
import { Pagination } from 'react-bootstrap';

export interface PaginationProps {
    onPageChange: (page: number) => void,
    totalCount: number,
    siblingCount: number,
    currentPage: number,
    pageSize: number,
    className?: string
}

const PaginationComponent: React.FC<PaginationProps> = ({
    onPageChange,
    totalCount,
    siblingCount,
    currentPage,
    pageSize,
    className
}) => {

    // Calcola il numero totale di pagine
    const totalPages = Math.ceil(totalCount / pageSize);

    if (totalPages <= 1) {
        return null;
    }

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const createPaginationItems = () => {
        const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item 
                    key={number} 
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };

    return (
        <Pagination className={className}>
            <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            />
            {createPaginationItems()}
            <Pagination.Next
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
            />
        </Pagination>
    );
};

export default PaginationComponent;

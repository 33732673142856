import { faCog, faEnvelopeOpen, faSignOutAlt, faUserCircle, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import { Container, Dropdown, Image, Nav, Navbar} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { JWTModel } from "../auth/jwtModel";
import { deleteJwtToken, getUserFromCookieUserToken } from "../utility/cookie";
import { HREF_LOGIN, HREF_PROFILO_UTENTE } from "../constants/constants";

function Navbars() {

  const userResult:JWTModel | null | undefined = getUserFromCookieUserToken();
  const navigate = useNavigate()

  const faiLogout = ()=>{
    console.log('logout')
    deleteJwtToken()
    navigate(HREF_LOGIN)
  }
  


  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
    <Container fluid className="px-0">
      <div className="d-flex justify-content-end w-100">
       
        <Nav className="align-items-center">
          

          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
              <div className="media d-flex align-items-center">
                <Image src={`https://ui-avatars.com/api/?name=${userResult?.nome.charAt(0)}+${userResult?.cognome.charAt(0)}`} className="user-avatar md-avatar rounded-circle" />
                <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                  {userResult && <span className="mb-0 font-small fw-bold">{userResult.nome} {userResult.cognome}</span>}
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
              <Dropdown.Item className="fw-bold" onClick={()=>{navigate(HREF_PROFILO_UTENTE)}}>
                <FontAwesomeIcon icon={faUserCircle} className="me-2" />Profilo
              </Dropdown.Item>
              
              <Dropdown.Divider />

              <Dropdown.Item className="fw-bold" onClick={()=> {faiLogout()}}>
                <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </div>
    </Container>
  </Navbar>
  )
}

export default Navbars;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ProdottoSearchRequestDto } from "../interface/prodottoSearchRequestDto"
import axios from "axios"
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants"
import { ProdottoInsertRequestDto } from "../interface/prodottoInsertRequestDto"
import { ProdottoUpdateRequestDto } from "../interface/prodottoUpdateRequestDto"

interface prodottoSlice {
    prodottoSearchResult: any | null,
    prodottoSearchStatus: string
    prodottoInsertResult: any | null,
    prodottoInsertStatus: string,
    prodottoDettaglioResult: any | null,
    prodottoDettaglioStatus: string,
    prodottoUpdateResult: any | null,
    prodottoUpdateStatus: string,
    prodottoDeleteStatus: string,
    
}

const initialState: prodottoSlice = {
    prodottoSearchResult: null,
    prodottoSearchStatus: '',
    prodottoInsertResult: null,
    prodottoInsertStatus: '',
    prodottoDettaglioResult: null,
    prodottoDettaglioStatus: '',
    prodottoUpdateResult: null,
    prodottoUpdateStatus: '',
    prodottoDeleteStatus: ''
}

export const searchProdotti = createAsyncThunk(
    'prodotto/search',
    async (args: ProdottoSearchRequestDto) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/prodotto/search?pageNum=${args.pageNum - 1}&name=${args.name}&description=${args.description}&idCatalogo=${args.idCatalogo}`)

        return response.data
    }
)

export const insertProdotto = createAsyncThunk(
    'prodotto/insert',
    async (args:ProdottoInsertRequestDto) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/prodotto/insert`, args)

        return response.data
    }
)

export const dettaglioProdotto = createAsyncThunk(
    'prodotto/dettaglio',
    async (id:string) =>{
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/prodotto/findById/${id}`)

        return response.data
    }
)

export const updateProdotto = createAsyncThunk(
    'prodotto/update',
    async (args:ProdottoUpdateRequestDto) => {
        const response = await axios.put(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/prodotto/update`, args)

        return response.data
    }
)

export const deleteProdotto = createAsyncThunk(
    'prodotto/delete',
    async (id: string) => {
        const response = await axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/prodotto/delete/${id}`)

        return response.data
    } 
)


export const prodottoSlice = createSlice({
    name: 'prodotto',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        //search
        builder.addCase(searchProdotti.pending, (state: prodottoSlice) => {
            state.prodottoSearchStatus = STATUS_PENDING;
        });
        builder.addCase(searchProdotti.fulfilled, (state: prodottoSlice, action: any) => {
            state.prodottoSearchStatus = STATUS_FULLFILLED;
            state.prodottoSearchResult = action.payload;
        });
        builder.addCase(searchProdotti.rejected, (state: prodottoSlice) => {
            state.prodottoSearchStatus = STATUS_REJECTED;
        });

        //insert
        builder.addCase(insertProdotto.pending, (state:prodottoSlice) =>{
            state.prodottoInsertStatus = STATUS_PENDING
        })
        builder.addCase(insertProdotto.fulfilled, (state:prodottoSlice, action:any) => {
            state.prodottoInsertStatus = STATUS_FULLFILLED;
            state.prodottoInsertResult = action.payload
        })
        builder.addCase(insertProdotto.rejected, (state:prodottoSlice) => {
            state.prodottoInsertStatus = STATUS_REJECTED
        })

        //dettaglio
        builder.addCase(dettaglioProdotto.pending, (state:prodottoSlice) => {
            state.prodottoDettaglioStatus = STATUS_PENDING
        })
        builder.addCase(dettaglioProdotto.fulfilled, (state:prodottoSlice, action:any) => {
            state.prodottoDettaglioStatus = STATUS_FULLFILLED;
            state.prodottoDettaglioResult = action.payload
        })
        builder.addCase(dettaglioProdotto.rejected, (state:prodottoSlice) => {
            state.prodottoDettaglioStatus = STATUS_REJECTED
        })

        //modifica
        builder.addCase(updateProdotto.pending, (state: prodottoSlice) =>{
            state.prodottoUpdateStatus = STATUS_PENDING
        }) 
        builder.addCase(updateProdotto.fulfilled, (state: prodottoSlice, action:any) =>{
            state.prodottoUpdateStatus = STATUS_FULLFILLED
            state.prodottoUpdateResult = action.payload
        })
        builder.addCase(updateProdotto.rejected, (state: prodottoSlice) =>{
            state.prodottoUpdateStatus = STATUS_REJECTED
        })

        //elimina
        builder.addCase(deleteProdotto.pending, (state: prodottoSlice) =>{
            state.prodottoDeleteStatus = STATUS_PENDING
        })
        builder.addCase(deleteProdotto.fulfilled, (state: prodottoSlice) =>{
            state.prodottoDeleteStatus = STATUS_FULLFILLED
        })
        builder.addCase(deleteProdotto.rejected, (state: prodottoSlice) =>{
            state.prodottoDeleteStatus = STATUS_REJECTED
        })
    }
})

export default prodottoSlice.reducer
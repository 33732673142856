import React from "react";
import { Col, Row } from "react-bootstrap";
import { GeneralInfoForm } from "../../components/generalUserForm";
import ProfileCardWidget from "../../components/profileCardWidget";

function Profilo() {

    return (
        <>
            <Row>
                <Col xs={12} xl={8}>
                    <GeneralInfoForm />
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <ProfileCardWidget />
                        </Col>
                        <Col xs={12}>
                            
                        </Col>
                    </Row>
                </Col>
            </Row></>
    )
}

export default Profilo;
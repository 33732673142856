import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants"


export interface authState {
    loginResult: any | null,
    loginStatus:string,
    registerResult: any | null,
    registerStatus: string,
    forgotPasswordEmailResult: any | null,
    forgotPasswordEmailStatus: string,
    verifyOtpResult: any | null,
    verifyOtpStatus: string,
    resetPasswordResult: any | null,
    resetPasswordStatus: string
}

const initialState: authState = {
    loginResult: null,
    loginStatus: '',
    registerResult: null,
    registerStatus: '',
    forgotPasswordEmailResult: null,
    forgotPasswordEmailStatus: '',
    verifyOtpResult: null,
    verifyOtpStatus: '',
    resetPasswordResult: null,
    resetPasswordStatus: ''
}

export interface loginRequestDto {
    email:string,
    password:string,
}

export interface registerRequestDto {
    firstname: string,
    lastname: string,
    email: string,
    password: string
}

interface emailForgotPasswordDto {
    email: string,
}

interface verifyOtpDto {
    email: string,
    otp:number | undefined
}

interface resetPasswordOtpDto {
    email: string,
    password: string,
    confirmPassword: string
}


export const login = createAsyncThunk(
    'auth/login',
    async (args: loginRequestDto) =>{
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/auth/authenticate`, args)

        return response.data
    }
)

export const register = createAsyncThunk(
    'auth/register',
    async (args: registerRequestDto) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/auth/register-dashboard`, args)

        return response.data
    }
)

export const forgotPasswordEmail = createAsyncThunk(
    'auth/forgotPasswordEmail',
    async (args: emailForgotPasswordDto) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/forgotPassword/verifyMail/${args.email}`)

        return response
    }
)

export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async (args: verifyOtpDto ) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/forgotPassword/verifyOtp/${args.otp}/${args.email}`)

        return response
    }
)

export const resetPassword = createAsyncThunk(
    'auth/resetPasswordOtp',
    async (args: resetPasswordOtpDto ) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/forgotPassword/changePassword/${args.email}`, {
            newPassword: args.password, 
            confirmNewPassword: args.confirmPassword
        })

        return response
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        resetRegisterStatus: (state) => {
            state.registerStatus = ''
        },
    },
    extraReducers: (builder) => {
        //login
        builder.addCase(login.pending, (state: authState) => {
            state.loginStatus = STATUS_PENDING;
        });
        builder.addCase(login.fulfilled, (state: authState, action: any) => {
            state.loginStatus = STATUS_FULLFILLED;
            state.loginResult = action.payload;
        });
        builder.addCase(login.rejected, (state: authState) => {
            state.loginStatus = STATUS_REJECTED;
        });

        //register
        builder.addCase(register.pending, (state: authState) => {
            state.registerStatus = STATUS_PENDING;
        });
        builder.addCase(register.fulfilled, (state: authState, action: any) => {
            state.registerStatus = STATUS_FULLFILLED;
            state.registerResult = action.payload;
        });
        builder.addCase(register.rejected, (state: authState) => {
            state.registerStatus = STATUS_REJECTED;
        });

        //forgotPassword email
        builder.addCase(forgotPasswordEmail.pending, (state: authState) => {
            state.forgotPasswordEmailStatus = STATUS_PENDING;
        });
        builder.addCase(forgotPasswordEmail.fulfilled, (state: authState, action: any) => {
            state.forgotPasswordEmailStatus = STATUS_FULLFILLED;
            state.forgotPasswordEmailResult = action.payload;
        });
        builder.addCase(forgotPasswordEmail.rejected, (state: authState) => {
            state.forgotPasswordEmailStatus = STATUS_REJECTED;
        });

        //forgotPassword verify otp
        builder.addCase(verifyOtp.pending, (state: authState) => {
            state.verifyOtpStatus = STATUS_PENDING;
        });
        builder.addCase(verifyOtp.fulfilled, (state: authState, action: any) => {
            state.verifyOtpStatus = STATUS_FULLFILLED;
            state.verifyOtpResult = action.payload;
        });
        builder.addCase(verifyOtp.rejected, (state: authState) => {
            state.verifyOtpStatus = STATUS_REJECTED;
        });

        //forgotPassword reset password otp
        builder.addCase(resetPassword.pending, (state: authState) => {
            state.resetPasswordStatus = STATUS_PENDING;
        });
        builder.addCase(resetPassword.fulfilled, (state: authState, action: any) => {
            state.resetPasswordStatus = STATUS_FULLFILLED;
            state.resetPasswordResult = action.payload;
        });
        builder.addCase(resetPassword.rejected, (state: authState) => {
            state.resetPasswordStatus = STATUS_REJECTED;
        });
    }

})

export const { resetRegisterStatus } = authSlice.actions;
export default authSlice.reducer
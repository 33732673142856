import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { CatalogoSearchRequestDto } from "../interface/catalogoSearchRequestDto"
import axios from "axios"
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants"
import { CatalogoInsertRequestDto } from "../interface/catalogoInsertRequestDto"
import { CatalogoUpdateRequestDto } from "../interface/catalogoUpdateRequestDto"

interface catalogoSlice {
    catalogoSearchResult: any| null,
    catalogoSearchStatus: string,
    catalogoInsertResult: any | null,
    catalogoInsertStatus: string,
    catalogoDettaglioResult: any | null,
    catalogoDettaglioStatus: string,
    catalogoUpdateResult: any | null,
    catalogoUpdateStatus: string,
    catalogoDeleteStatus: string,
    catalogoGetAllResult: any | null,
    catalogoGetAllStatus: string
}

const initialState: catalogoSlice = {
    catalogoSearchResult: null,
    catalogoSearchStatus: '',
    catalogoInsertResult: null,
    catalogoInsertStatus: '',
    catalogoDettaglioResult: null,
    catalogoDettaglioStatus: '',
    catalogoUpdateResult: null,
    catalogoUpdateStatus: '',
    catalogoDeleteStatus: '',
    catalogoGetAllResult: null,
    catalogoGetAllStatus: ''
}

export const searchCataloghi = createAsyncThunk(
    'catalogo/search',
    async (args: CatalogoSearchRequestDto) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/search?${args.pageNum - 1}&id=${args.id}&nome=${args.nome}`)

        return response.data
    }
)

export const insertCatalogo = createAsyncThunk(
    'catalogo/insert',
    async (args: CatalogoInsertRequestDto) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/insert`, args)

        return response.data
    }
)

export const dettaglioCatalogo = createAsyncThunk(
    'catalogo/dettaglio',
    async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/findById/${id}`)

        return response.data
    }
)

export const updateCatalogo = createAsyncThunk(
    'catalogo/update',
    async (args: CatalogoUpdateRequestDto) => {
        const response = await axios.put(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/update`, args)

        return response.data
    }
)

export const deleteCatalogo = createAsyncThunk(
    'catalogo/elimina',
    async (id:string) =>{
        const response =await axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/delete/${id}`)

        return response.data
    }
)

export const getAllCatalogo = createAsyncThunk(
    'catalogo/getAll',
    async () => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/catalogo/findAll`)

        return response.data
    }
)

export const catalogoSlice = createSlice({
    name: 'catalogo',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        //search 
        builder.addCase(searchCataloghi.pending, (state:catalogoSlice) =>{
            state.catalogoSearchStatus = STATUS_PENDING
        });
        builder.addCase(searchCataloghi.fulfilled, (state:catalogoSlice, action:any) => {
            state.catalogoSearchStatus = STATUS_FULLFILLED;
            state.catalogoSearchResult = action.payload;
        })
        builder.addCase(searchCataloghi.rejected, (state:catalogoSlice) => {
            state.catalogoSearchStatus = STATUS_REJECTED
        })

        // insert 
        builder.addCase(insertCatalogo.pending, (state:catalogoSlice) => {
            state.catalogoInsertStatus = STATUS_PENDING
        });
        builder.addCase(insertCatalogo.fulfilled, (state:catalogoSlice, action:any) => {
            state.catalogoInsertStatus = STATUS_FULLFILLED;
            state.catalogoInsertResult = action.payload
        })
        builder.addCase(insertCatalogo.rejected, (state:catalogoSlice) => {
            state.catalogoInsertStatus = STATUS_REJECTED
        })

        //dettaglio
        builder.addCase(dettaglioCatalogo.pending, (state:catalogoSlice) => {
            state.catalogoDettaglioStatus = STATUS_PENDING
        })
        builder.addCase(dettaglioCatalogo.fulfilled, (state:catalogoSlice, action:any) => {
            state.catalogoDettaglioStatus = STATUS_FULLFILLED;
            state.catalogoDettaglioResult = action.payload
        })
        builder.addCase(dettaglioCatalogo.rejected, (state:catalogoSlice) => {
            state.catalogoDettaglioStatus = STATUS_REJECTED
        })

        //modifica
        builder.addCase(updateCatalogo.pending, (state: catalogoSlice) =>{
            state.catalogoUpdateStatus = STATUS_PENDING
        })
        builder.addCase(updateCatalogo.fulfilled, (state: catalogoSlice, action:any) =>{
            state.catalogoUpdateStatus = STATUS_FULLFILLED
            state.catalogoUpdateResult = action.payload
        })
        builder.addCase(updateCatalogo.rejected, (state: catalogoSlice) =>{
            state.catalogoUpdateStatus = STATUS_REJECTED
        })

        //elimina
        builder.addCase(deleteCatalogo.pending, (state: catalogoSlice) =>{
            state.catalogoDeleteStatus = STATUS_PENDING
        })
        builder.addCase(deleteCatalogo.fulfilled, (state: catalogoSlice) =>{
            state.catalogoDeleteStatus = STATUS_FULLFILLED
        })
        builder.addCase(deleteCatalogo.rejected, (state: catalogoSlice) =>{
            state.catalogoDeleteStatus = STATUS_REJECTED
        })

        //getAll
        builder.addCase(getAllCatalogo.pending, (state:catalogoSlice) =>{
            state.catalogoGetAllStatus = STATUS_PENDING
        })
        builder.addCase(getAllCatalogo.fulfilled, (state: catalogoSlice, action:any) => {
            state.catalogoGetAllStatus = STATUS_FULLFILLED;
            state.catalogoGetAllResult = action.payload
        })
        builder.addCase(getAllCatalogo.rejected, (state:catalogoSlice) =>{
            state.catalogoGetAllStatus = STATUS_REJECTED
        })
    }
})

export default catalogoSlice.reducer
import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { Alert, Button, ButtonGroup, Card, Dropdown, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH, faEye, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../components/Pagination/Pagination";
import { z } from "zod";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { ordiniSearchRequestDto } from "../../interface/ordiniSearchRequestDto";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchOrdini, updateStato } from "../../store/ordiniSlice";
import { STATUS_FULLFILLED, STATUS_REJECTED } from "../../store/store-constants";
import { format, startOfDay, endOfDay } from 'date-fns';
import { useNavigate } from "react-router";
import { HREF_DETTAGLIO_ORDINE } from "../../constants/constants";

// Mappa degli stati ai colori
const statusClassMap: { [key: string]: string } = {
  NUOVO: 'danger', // Rosso
  IN_CORSO: 'warning', // Giallo
  COMPLETO: 'success', // Verde
};



const schema = z.object({
  stato: z.string().optional(),
  dataCreazioneInizio: z.string().optional(),
  dataCreazioneFine: z.string().optional()
});

const initialValues: ordiniSearchRequestDto = {
  stato: '',
  dataCreazioneFine: '',
  dataCreazioneInizio: '',
  pageNum: 1,
};

function Ordini() {
  const [ordini, setOrdini] = useState<any>({
    data: [],
    totalElements: 0,
    pageNum: 1,
    pageSize: 10,
  });

  const { ordiniSearchResult, ordiniSearchStatus } = useAppSelector((store) => store.ordini);

  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const form = useFormik({
    initialValues: initialValues,
    validationSchema: toFormikValidationSchema(schema),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  let hasResults =
    ordiniSearchResult && ordiniSearchResult.totalElements
      ? ordiniSearchResult.totalElements > 0
      : false;

  const handleSubmit = (values: ordiniSearchRequestDto) => {
    // Preparare la data e l'ora nel formato ISO 8601
    const { dataCreazioneInizio, dataCreazioneFine } = values;
    const formattedDataCreazioneInizio = dataCreazioneInizio
      ? format(startOfDay(new Date(dataCreazioneInizio)), "yyyy-MM-dd'T'HH:mm:ss")
      : '';

    const formattedDataCreazioneFine = dataCreazioneFine
      ? format(endOfDay(new Date(dataCreazioneFine)), "yyyy-MM-dd'T'HH:mm:ss")
      : '';

    // Aggiornare i valori nel form
    dispatch(searchOrdini({
      ...values,
      dataCreazioneInizio: formattedDataCreazioneInizio,
      dataCreazioneFine: formattedDataCreazioneFine
    }));
  };


  useEffect(() => {
    dispatch(searchOrdini(initialValues))
  }, [])

  useEffect(() => {
    setOrdini(ordiniSearchResult)
  }, [ordiniSearchResult])

  const reset = () => {
    form.resetForm();
    setCurrentPage(1);
    form.handleSubmit();
  }

  const handlePageChange = (pageNum: number) => {
    form.setFieldValue('pageNum', pageNum)
    setCurrentPage(pageNum)
    form.handleSubmit()
  };

  const avviaRicerca = () => {
    form.setFieldValue('pageNum', 1);
    setCurrentPage(1);
    form.handleSubmit()
  }

  const aggiornaStato = async (ordine: any) => {
    if (ordine.stato == "NUOVO") {
      let args = {
        id: ordine.id,
        stato: "IN_CORSO"
      }

      await dispatch(updateStato(args))
      form.handleSubmit()
    } else if (ordine.stato == "IN_CORSO") {
      let args = {
        id: ordine.id,
        stato: "COMPLETATO"
      }

      await dispatch(updateStato(args))
      form.handleSubmit()
    }

  }

  return (
    <>
      <PageHeader title="Ordini" />
      <div className="mt-4">
        <div className="row mb-4">
          <div className="col-md-4">
            <label htmlFor="stato">Stato</label>
            <select
              id="stato"
              className="form-control"
              name="stato"
              value={form.values.stato}
              onChange={form.handleChange}
            >
              <option value=''>Seleziona...</option>
              <option value='NUOVO'>NUOVO</option>
              <option value='IN_CORSO'>IN CORSO</option>
              <option value='COMPLETATO'>COMPLETATO</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="data-creazione-da">Data creazione da</label>
            <input
              type="date"
              id="data-creazione-da"
              className="form-control"
              name="dataCreazioneInizio"
              value={form.values.dataCreazioneInizio}
              onChange={form.handleChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="data-creazione-a">Data creazione a</label>
            <input
              type="date"
              id="data-creazione-a"
              className="form-control"
              name="dataCreazioneFine"
              value={form.values.dataCreazioneFine}
              onChange={form.handleChange}
            />
          </div>

        </div>
        <div className="d-flex justify-content-end mb-4">
          <button
            className="btn btn-outline-primary mt-2 me-2"
            type="button"
            id="button-cln"
            onClick={() => { reset() }}
          >
            Reset
          </button>
          <button className="btn btn-primary mt-2" type="button" id="button-addon2" onClick={() => avviaRicerca()}>
            <FontAwesomeIcon icon={faSearch} className="me-1" />
            Cerca
          </button>
        </div>
      </div>
      {ordini != null && ordiniSearchStatus === STATUS_FULLFILLED && ordini.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
      {ordiniSearchStatus === STATUS_FULLFILLED && hasResults && (
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">#</th>
                  <th className="border-bottom">Utente</th>
                  <th className="border-bottom">Data creazione</th>
                  <th className="border-bottom">Stato</th>
                  <th className="border-bottom"></th>
                </tr>
              </thead>
              <tbody>
                {ordini != null && ordini.data.map((t: any) => (
                  <tr key={t.id}>
                    <td>{t.id}</td>
                    <td>
                      <span className="fw-normal">{t.nomeUtente} {t.cognomeUtente}</span>
                    </td>
                    <td>
                      <span className="fw-normal">
                        {format(new Date(t.dataCreazione), 'dd/MM/yyyy')}
                      </span>
                    </td>
                    <td>
                      <span className={`fw-normal text-${statusClassMap[t.stato] || 'secondary'}`}>
                        {t.stato}
                      </span>
                    </td>
                    <td>
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                          <span className="icon icon-sm">
                            <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={()=> {navigate(`${HREF_DETTAGLIO_ORDINE}/${t.id}`)}}>
                            <FontAwesomeIcon icon={faEye} className="me-2" /> Dettaglio
                          </Dropdown.Item>
                          {t.stato != "COMPLETATO" && <Dropdown.Item onClick={() => { aggiornaStato(t) }}>
                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Aggiorna stato
                          </Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              {ordini != null ? (<PaginationComponent
                totalCount={ordini.totalElements}
                siblingCount={1}
                currentPage={currentPage}
                pageSize={ordini.pageSize}
                onPageChange={handlePageChange}
                className="justify-content-center"
              />) : <></>}
            </Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Ordini;

import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { useNavigate } from "react-router";
import { faEdit, faEllipsisH, faEye, faPlus, faSearch, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { z } from "zod";
import { CatalogoSearchRequestDto } from "../../interface/catalogoSearchRequestDto";
import { useFormik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteCatalogo, searchCataloghi } from "../../store/catalogoSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Alert, Button, ButtonGroup, Card, Dropdown, Modal, Table } from "react-bootstrap";
import PaginationComponent from "../../components/Pagination/Pagination";
import TruncateText from "../../components/truncateText";
import { HREF_DETTAGLIO_CATALOGO, HREF_MODIFICA_CATALOGO, HREF_NUOVO_CATALOGO } from "../../constants/constants";

const schema = z.object({
    id: z.string().optional(),
    nome: z.string().optional()
})

const initialValues: CatalogoSearchRequestDto = {
    id: '',
    nome: '',
    pageNum: 1
};

function Catalogo() {

    const dispatch = useAppDispatch();
    const { catalogoSearchResult, catalogoSearchStatus } = useAppSelector((state) => state.catalogo)
    const [currentPage, setCurrentPage] = useState(1);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [catalogoToDelete, setCatalogoToDelete] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);


    const [cataloghi, setCataloghi] = useState<any>({
        data: [],
        totalElements: 0,
        pageNum: 1,
        pageSize: 10,
    })

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            handleSubmit(values)
        }
    })

    const handleSubmit = (values: CatalogoSearchRequestDto) => {
        dispatch(searchCataloghi(values))
    }

    useEffect(() => {
        dispatch(searchCataloghi(initialValues))
    }, [])

    useEffect(() => {
        setCataloghi(catalogoSearchResult)
    }, [catalogoSearchResult])

    const reset = () => {
        form.resetForm();
        setCurrentPage(1)
        form.handleSubmit();
    }

    const handlePageChange = (pageNum: number) => {
        form.setFieldValue('pageNum', pageNum)
        setCurrentPage(pageNum)
        form.handleSubmit()
    };

    const avviaRicerca = () => {
        form.setFieldValue('pageNum', 1);
        setCurrentPage(1);
        form.handleSubmit()
    }

    let hasResult =
        catalogoSearchResult && catalogoSearchResult.totalElements
            ? catalogoSearchResult.totalElements > 0
            : false;

    const navigate = useNavigate();

    const tastoAggiungiCatalogo = {
        buttonProperties: { variant: 'outline-primary' },
        title: 'Nuovo catalogo',
        buttonColor: 'primary',
        action: () => navigate(HREF_NUOVO_CATALOGO),
        showButtonIcon: true,
        icon: faPlus,
        iconColor: 'primary',
    };

   
    const handleDelete = async () => {
        if (catalogoToDelete) {
            try {
                // Verifica se ci sono prodotti associati all'interno dell'oggetto catalogoToDelete
               
                if (catalogoToDelete && catalogoToDelete.prodotti.length > 0) {
                    setError('Impossibile eliminare il catalogo finché ci sono dei prodotti associati.');
                } else {
                    await dispatch(deleteCatalogo(catalogoToDelete.id));
                    setShowDeleteModal(false);
                    setCatalogoToDelete(null);
                    dispatch(searchCataloghi(form.values)); // Ricarica i dati dopo l'eliminazione
                }
            } catch (err) {
                console.error('Error deleting catalogo:', err);
            }
        }
    };

    const handleShowDeleteModal = (catalogo: any) => {
        setCatalogoToDelete(catalogo);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setCatalogoToDelete(null);
        setError(null);
    };

    const modalDelete = (catalogo: any) => {
        handleShowDeleteModal(catalogo);
    };

    return (
        <>
            <PageHeader title="Catalogo" buttons={[tastoAggiungiCatalogo]} />
            <div className="mt-4">
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label htmlFor="idCatalogo">Id</label>
                        <input
                            type="text"
                            id="idCatalogo"
                            placeholder="Inserisci l' identificativo"
                            className="form-control"
                            name="id"
                            value={form.values.id}
                            onChange={form.handleChange}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="nomeCatalogo">Nome</label>
                        <input
                            type="text"
                            id="nomeCatalogo"
                            placeholder="Inserisci il nome"
                            className="form-control"
                            name="nome"
                            value={form.values.nome}
                            onChange={form.handleChange}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mb-4">
                    <button
                        className="btn btn-outline-primary mt-2 me-2"
                        type="button"
                        id="button-cln"
                        onClick={() => { reset() }}
                    >
                        Reset
                    </button>
                    <button className="btn btn-primary mt-2" type="button" id="button-addon2" onClick={() => avviaRicerca()}>
                        <FontAwesomeIcon icon={faSearch} className="me-1" />
                        Cerca
                    </button>
                </div>
            </div>
            {cataloghi != null && catalogoSearchStatus === STATUS_FULLFILLED && cataloghi.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
            {catalogoSearchStatus === STATUS_FULLFILLED && hasResult && (
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="pt-0">
                        <Table hover className="user-table align-items-center">
                            <thead>
                                <tr>
                                    <th className="border-bottom">#</th>
                                    <th className="border-bottom">Nome</th>
                                    <th className="border-bottom">Descrizione</th>
                                    <th className="border-bottom">N. Prodotti</th>
                                    <th className="border-bottom"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {cataloghi != null && cataloghi.data.map((t: any, i: any) => (
                                    <tr key={t.id}>
                                        <td>{t.id}</td>
                                        <td>
                                            <span className="fw-normal">{t.nome}</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">{<TruncateText text={t.description} maxLength={50} idTooltip={`${i}`} />}{ }</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">{t.prodotti.length}</span>
                                        </td>
                                        <td>
                                            <Dropdown as={ButtonGroup}>
                                                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                    <span className="icon icon-sm">
                                                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => navigate(`${HREF_DETTAGLIO_CATALOGO}/${t.id}`)}>
                                                        <FontAwesomeIcon icon={faEye} className="me-2" /> Dettaglio
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => navigate(`${HREF_MODIFICA_CATALOGO}/${t.id}`)}>
                                                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Modifica
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="text-danger" onClick={() => { modalDelete(t) }}>
                                                        <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Elimina
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                            {cataloghi != null ? (<PaginationComponent
                                totalCount={cataloghi.totalElements}
                                siblingCount={1}
                                currentPage={currentPage}
                                pageSize={cataloghi.pageSize}
                                onPageChange={handlePageChange}
                                className="justify-content-center"
                            />) : <></>}
                        </Card.Footer>
                    </Card.Body>
                </Card>
            )}

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Eliminazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error ? (
                        <Alert variant="danger">{error}</Alert>
                    ) : (
                        <>
                            <p>Sei sicuro di voler eliminare questo catalogo?</p>
                            <p>Questa azione non può essere annullata.</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Annulla
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Elimina
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Catalogo;

import React, { FC } from 'react'
import { Button, ButtonProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface ButtonsInterface {
    buttonProperties?: ButtonProps,
    title: string,
    action: Function,
    buttonColor: string,
    showButtonIcon?: boolean,
    icon?: any,
    iconColor?: string,
    disabled?: boolean
}

export interface pageHeader {
    title?: string;
    urlBack?: string
    showIcon?: boolean
    titleClass?: string
    buttons?: ButtonsInterface[],
    info?: React.ReactNode
}


const PageHeader = (props: pageHeader) => {

    const navigate = useNavigate();

    const toBack = () => {
        if (props.urlBack === 'INDIETRO') {
            navigate(-1)
        }
        if (props.urlBack) {
            navigate(props.urlBack)
        }
    }

    return (
        <div className='d-flex align-items-center justify-content-between mb-3'>
            <div className='d-flex align-items-center'>
                {props.showIcon && <FontAwesomeIcon className="mr-3" color="" style={{ cursor: 'pointer' }} onClick={() => toBack()} icon={faArrowLeft} size='sm' />}
                <h1 className={props.titleClass || ''}>{props.title!} <span className='h6' style={{verticalAlign: 'middle'}}>{props.info}</span></h1>
            </div>
            <div>
                {props.buttons && props.buttons.map((button, i) =>

                    <Button key={i}
                        {...button.buttonProperties}
                        disabled={button.disabled}
                        color={button.buttonColor || ''}
                        className={`btn-sm ${props.buttons?.length! && i < props.buttons?.length! - 1 ? 'mr-2' : ''}`}
                        onClick={() => button.action()}>
                        {button.showButtonIcon && <FontAwesomeIcon color={button.iconColor!} icon={button.icon!} className='icon-align' size='xs' />}
                        {button.title}

                    </Button>

                )
                }
            </div>
        </div>
    )
}

export default PageHeader
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants";

interface utenteSlice {
    dettaglioUtenteResult: any |null;
    dettaglioUtenteStatus: string;
    utentiDaAttivareResult: any | null;
    utentiDaAttivareStatus: string;
    utenteAttivatoResult: any | null;
    utenteAttivatoStatus: string;
    modificaPasswordResult: any | null;
    modificaPasswordStatus: string;
}

const initialState: utenteSlice = {
    dettaglioUtenteResult: null,
    dettaglioUtenteStatus: '',
    utentiDaAttivareResult: null,
    utentiDaAttivareStatus: '',
    utenteAttivatoResult: null,
    utenteAttivatoStatus: '',
    modificaPasswordResult: null,
    modificaPasswordStatus: ''
}

export const utentiDaAttivare = createAsyncThunk(
    'utente/utentiDaAttivare',
    async (pageNum:any) =>{
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/user/utentiDaAttivate?pageNum=${pageNum - 1}`)

        return response.data
    }
)

export const dettaglioUtente = createAsyncThunk(
    'utente/dettaglio',
    async (id: string) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/user/findById/${id}`)

        return response.data
    }
)

export const attivaUtente = createAsyncThunk(
    'utente/attiva',
    async (id: string) => {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/user/attivaUtente/${id}`)

        return response.data
    }
)

export const modificaPassword = createAsyncThunk(
    'utente/modificaPassword',
    async (args:any) =>{
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/user/update-password`, args)

        return response.data
    }
)

export const utenteSlice = createSlice({
    name: 'utente',
    initialState,
    reducers: {},

    extraReducers: (builder) =>{
        //dettaglio
        builder.addCase(dettaglioUtente.pending, (state:utenteSlice) => {
            state.dettaglioUtenteStatus = STATUS_PENDING
        })
        builder.addCase(dettaglioUtente.fulfilled, (state:utenteSlice, action:any) => {
            state.dettaglioUtenteStatus = STATUS_FULLFILLED;
            state.dettaglioUtenteResult = action.payload
        })
        builder.addCase(dettaglioUtente.rejected, (state:utenteSlice) => {
            state.dettaglioUtenteStatus = STATUS_REJECTED
        })

        //utenti da attivare list
        builder.addCase(utentiDaAttivare.pending, (state:utenteSlice) => {
            state.utentiDaAttivareStatus = STATUS_PENDING
        })
        builder.addCase(utentiDaAttivare.fulfilled, (state:utenteSlice, action:any) => {
            state.utentiDaAttivareStatus = STATUS_FULLFILLED;
            state.utentiDaAttivareResult = action.payload
        })
        builder.addCase(utentiDaAttivare.rejected, (state:utenteSlice) => {
            state.utentiDaAttivareStatus = STATUS_REJECTED
        })

        //attiva utente 
        builder.addCase(attivaUtente.pending, (state:utenteSlice) => {
            state.utenteAttivatoStatus = STATUS_PENDING
        })
        builder.addCase(attivaUtente.fulfilled, (state:utenteSlice, action:any) => {
            state.utenteAttivatoStatus = STATUS_FULLFILLED;
            state.utenteAttivatoResult = action.payload
        })
        builder.addCase(attivaUtente.rejected, (state:utenteSlice) => {
            state.utenteAttivatoStatus = STATUS_REJECTED
        })

        //modifica password
        builder.addCase(modificaPassword.pending, (state: utenteSlice) =>{
            state.modificaPasswordStatus = STATUS_PENDING
        })
        builder.addCase(modificaPassword.fulfilled, (state:utenteSlice, action:any) =>{
            state.modificaPasswordResult = action.payload;
            state.modificaPasswordStatus = STATUS_FULLFILLED
        })
        builder.addCase(modificaPassword.rejected, (state:utenteSlice) =>{
            state.modificaPasswordStatus = STATUS_REJECTED
        })
    }
})

export default utenteSlice.reducer
import React, { useEffect } from "react";
import { useAppSelector } from "../hooks";
import { toast, Bounce } from "react-toastify";
import { NOTIFICA_STATO } from "../interface/notifica";


function NotificaComponent() {
    const { notifica } = useAppSelector((state) => state.loader);

    useEffect(() => {
        console.log('show notifica')
        console.log(notifica)
        if (notifica) {
            const { tipo, messaggio, titolo } = notifica;

            switch (tipo) {
                case NOTIFICA_STATO.success:
                    toast.success(messaggio, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "dark",
                        transition: Bounce,
                        toastId: titolo // Unique ID for the toast
                    });
                    break;
                case NOTIFICA_STATO.error:
                    toast.error(messaggio, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "dark",
                        transition: Bounce,
                        toastId: titolo // Unique ID for the toast
                    });
                    break;
                case NOTIFICA_STATO.info:
                    toast.info(messaggio, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "dark",
                        transition: Bounce,
                        toastId: titolo // Unique ID for the toast
                    });
                    break;
                case NOTIFICA_STATO.warning:
                    toast.warning(messaggio, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "dark",
                        transition: Bounce,
                        toastId: titolo // Unique ID for the toast
                    });
                    break;
                default:
                    break;
            }
        }
    }, [notifica]);

    return null; // Non renderizzare nulla
}

export default NotificaComponent;

import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { HREF_CATALOGHI } from "../../constants/constants";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { dettaglioCatalogo } from "../../store/catalogoSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Badge, Button, Card, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function DettaglioCatalogo() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { catalogoDettaglioResult, catalogoDettaglioStatus } = useAppSelector((state) => state.catalogo);
    const [catalogo, setCatalogo] = useState<any>(null);

    useEffect(() => {
        if (id != undefined) {
            dispatch(dettaglioCatalogo(id));
        }
    }, [id]);

    useEffect(() => {
        if (catalogoDettaglioStatus === STATUS_FULLFILLED) {
            setCatalogo(catalogoDettaglioResult);
        }
    }, [catalogoDettaglioStatus]);

    return (
        <>
            <PageHeader title="Dettaglio Catalogo" urlBack={HREF_CATALOGHI} showIcon={true} />
            <div className="container mt-4">
                <Card border="light" className="shadow-lg rounded">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Id: </span>
                                    <span className="text-muted"> {catalogo?.id}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Nome: </span>
                                    <span className="text-muted">{catalogo?.nome}</span>
                                </Card.Text>

                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Descrizione: </span>
                                    <span className="text-muted"> {catalogo?.description}</span>
                                </Card.Text>

                            </div>

                        </div>
                        <Card.Header className="fs-4 fw-bold mb-3">Prodotti</Card.Header>
                        <ListGroup variant="flush">
                            {catalogo?.prodotti.length ? (
                                catalogo.prodotti.map((prodotto: any) => (
                                    <ListGroup.Item key={prodotto.id} className="d-grid gap-2">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Codice:</strong> <br />
                                                <span className="text-muted">{prodotto.codice}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Nome:</strong> <br />
                                                <span className="text-muted">{prodotto.name}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Descrizione:</strong> <br />
                                                <span className="text-muted">{prodotto.description}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Grammatura:</strong> <br />
                                                <span className="text-muted">{prodotto.grammatura}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Pezzi per cartone:</strong> <br />
                                                <span className="text-muted">{prodotto.pezziPerCartone}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Tipo:</strong> <br />
                                                <span className="text-muted">{prodotto.tipo}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Unità di misura:</strong> <br />
                                                <span className="text-muted">{prodotto.unitaDiMisura}</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item className="text-center">Nessun prodotto disponibile</ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default DettaglioCatalogo;

import React from 'react';
import Sidebar from './components/sidebar';
import Ordini from './pages/ordini/ordini';
import { Navigate, Route, Routes } from 'react-router';
import Layout from './components/layout';
import { HREF_ACCETTA_UTENTI, HREF_CATALOGHI, HREF_DETTAGLIO_CATALOGO, HREF_DETTAGLIO_ORDINE, HREF_DETTAGLIO_PRODOTTO, HREF_DETTAGLIO_RESO, HREF_LOGIN, HREF_MODIFICA_CATALOGO, HREF_MODIFICA_PRODOTTO, HREF_NUOVO_CATALOGO, HREF_NUOVO_PRODOTTO, HREF_ORDINI, HREF_PRODOTTI, HREF_PROFILO_UTENTE, HREF_RECUPERA_PASSWORD, HREF_REGISTRATI, HREF_RESI } from './constants/constants';
import AcceptUser from './pages/accettaUtenti/acceptUser';
import Catalogo from './pages/catalogo/catalogo';
import Prodotto from './pages/prodotti/prodotto';
import Login from './pages/auth/login';
import PageNotFound from './pages/pageNotFound';
import Singup from './pages/auth/singup';
import ForgotPassword from './pages/auth/recuperaPassword';
import { ToastContainer } from 'react-bootstrap';
import NuovoCatalogo from './pages/catalogo/nuovoCatalogo';
import DettaglioCatalogo from './pages/catalogo/dettaglioCatalogo';
import ModificaCatalogo from './pages/catalogo/modificaCatalogo';
import NuovoProdotto from './pages/prodotti/nuovoProdotto';
import DettaglioProdotto from './pages/prodotti/dettaglioProdotto';
import ModificaProdotto from './pages/prodotti/modificaProdotto';
import DettaglioOrdine from './pages/ordini/dettaglioOrdine';
import Profilo from './pages/profilo/profilo';
import Resi from './pages/resi/resi';
import DettaglioResi from './pages/resi/dettaglioResi';

function App() {


  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Navigate to={HREF_LOGIN} replace />} />
        <Route path={HREF_LOGIN} element={<Login />} />
        <Route path='*' element={<PageNotFound />} />
        <Route path={HREF_REGISTRATI} element={<Singup/>}/>
        <Route path={HREF_RECUPERA_PASSWORD} element={<ForgotPassword />}/>
        <Route element={<Layout />}>
          <Route path={HREF_ACCETTA_UTENTI} element={<AcceptUser />}/>

          <Route path={HREF_CATALOGHI} element={<Catalogo />}/>
          <Route path={HREF_NUOVO_CATALOGO} element={<NuovoCatalogo />}/>
          <Route path={`${HREF_DETTAGLIO_CATALOGO}/:id`} element={<DettaglioCatalogo />} />
          <Route path={`${HREF_MODIFICA_CATALOGO}/:id`} element={<ModificaCatalogo />} />

          <Route path={HREF_PRODOTTI} element={<Prodotto />}/>
          <Route path={HREF_NUOVO_PRODOTTO} element={<NuovoProdotto />} />
          <Route path={`${HREF_DETTAGLIO_PRODOTTO}/:id`} element={<DettaglioProdotto />} />
          <Route path={`${HREF_MODIFICA_PRODOTTO}/:id`} element={<ModificaProdotto />} />

          <Route path={HREF_ORDINI} element={<Ordini />} />
          <Route path={`${HREF_DETTAGLIO_ORDINE}/:id`} element={<DettaglioOrdine />} />

          <Route path={HREF_RESI} element={<Resi />} />
          <Route path={`${HREF_DETTAGLIO_RESO}/:id`} element={<DettaglioResi />} />

          <Route path={HREF_PROFILO_UTENTE} element={<Profilo />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect } from 'react';
import { Fade, Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import './loader.scss'; // Assicurati che il percorso sia corretto

export interface PageLoaderProps {
    loadingMessage?: string;
}

const PageLoader = (props: PageLoaderProps) => {
    const { loaderActive } = useAppSelector((state) => state.loader);

    useEffect(() => {
        console.log('PageLoader updated', loaderActive);
    }, [loaderActive]);

    return (
        loaderActive > 0 ? (
            <div className="page-loader-container">
                <Fade in={loaderActive > 0}>
                    <div className="page-loader">
                        <span className="d-flex justify-content-center mb-2">
                            <Spinner animation="border" />
                        </span>
                        <h3 className="text-center text-primary">
                            {props.loadingMessage || 'Attendere...'}
                        </h3>
                    </div>
                </Fade>
                <div className="overlay"></div> {/* Overlay per rendere la pagina non cliccabile */}
            </div>
        ) : null
    );
};

export default PageLoader;
